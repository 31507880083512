.mainContainer {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-family: 'Major Mono Display';
    position: absolute;
    right: 2rem;
    top: 2rem;
    z-index: 99;
}

.mainContainer button {
    font-family: 'Roboto';
    font-size: 1.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
    color: black;
    background: linear-gradient(90deg, white 30%, #4169E1);
    transition: transform 0.3s ease-in-out;
    box-shadow: 2px 2px 20px #ffffff70;
}

.mainContainer button:hover {
    transform: scale(1.1) translateX(-2rem);
}

.canvasContainer {
    margin: 0;
    width: 100vw;
    height: 100vh
}

.fallbackContainer {
    position: relative;
}

.fallbackContainer h3 {
    color: white;
    text-align: center;
    margin: 2rem 0;
    z-index: 99;
}

@media (max-width: 480px) {

    /* CSS rules for mobile devices go here */
    .mainContainer {
        align-items: center;
        top: 2rem;
        left: 0;
        right: 0;
        text-align: center;
    }

    .mainContainer h1 {
        font-size: 1.5rem
    }

    .mainContainer button:hover {
        transform: scale(1.1) translateX(0);
    }

    .canvasContainer {
        margin: 12rem 0 0 0;
    }
}