@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #131313;
  background: linear-gradient(90deg, black, #000080, #4169E1, #000080, black);
  background-size: 400% 100%;
  animation: gradientAnimation 16s ease-in-out infinite;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

canvas {
  height: 80vh;
  width: 100%;
}

h1, h2, a, button {
  margin: 0.5rem 0;
}

h1 {
  font-family: 'Major Mono Display';
}

h2 {
  font-family: 'Roboto';
}

a {
  color: white;
  text-decoration-color: royalblue;
  text-decoration-line: underline;
  transition: transform 0.3s ease-in-out;
}

a:hover {
  transform: scale(1.1);
}

a:visited {
  color: white;
  text-decoration-color: royalblue;
  opacity: 0.7;
}